import { handleError } from './apiErrorHandler';
import AxiosService from '@deltax/api-client';

const getPortalDomain = () => {
  if(typeof module.hot !== 'undefined'){
    return 'roimatic.adbox.pro'
  }
  var queryParameters = new URLSearchParams(window.location.search);
  var portalDomain = queryParameters.get('portalDomain');

  if(portalDomain){
    return portalDomain;
  }

  return window.location.host;
}

const axiosService =  new AxiosService(process.env.VUE_APP_API_URL, process.env.VUE_APP_DELTAX_CLIENT_APP_ID, getPortalDomain(), handleError);

export default axiosService.axiosInstance;

export const apiMethods = axiosService.getApiMethods();

export const oAuth2AxiosInstance = axiosService.oAuth2AxiosInstance;