// Sidebar Routers
// Sidebar Routers

export const menuItemKeys = {
  STORE: 'Store',
  PRODUCT_FEED: 'Product Feed',
  LINK_ASSETS: 'Link Assets',
  ADD_AUDIENCE: 'Add Audience',
  DASHBOARD: 'Dashboard',
  WALLET: 'Wallet',
  PUBLISHED_REPORTS: 'Reports',
  PRODUCT_INSIGHTS: 'Product Insights',
  POSTBACK_URLS: 'Postback Urls',
  MEDIA_DATA_UPLOAD: 'Media Data Upload',
  TRACKING_LINK: 'Tracking Link',
  ADHOC_QUERY_REPORTS: 'Adhoc Query Reports',
  REVIEW_MANAGEMENT: 'Review Management',
  DEALS_DASHBOARD: 'Deals Dashboard',
  ADVANCED_INSIGHTS: 'Advanced Insights'
}

export const settingsMenu = [
  {
    action: 'store_mall_directory',
    title: 'message.storeProfile',
    active: false,
    items: null,
    routeName: 'storeSetup',
    exact: true,
    key: menuItemKeys.STORE_PROFILE
  },
  {
    action: 'assignment',
    title: 'message.productFeed',
    active: false,
    items: null,
    routeName: 'productFeed',
    exact: true,
    key: menuItemKeys.PRODUCT_FEED
  }
]

export const featuresMenu = [
  {
    action: 'layers',
    title: 'message.assets',
    active: false,
    items: null,
    routeName: 'assets',
    exact: true,
    key: menuItemKeys.LINK_ASSETS
  },
  {
    action: 'people',
    title: 'message.audience',
    active: true,
    items: null,
    routeName: 'audience',
    exact: true,
    key: menuItemKeys.ADD_AUDIENCE
  },
  {
    action: 'rate_review',
    title: 'message.reviewManagement.reviewManagement',
    active: true,
    items: null,
    routeName: 'reviewManagement',
    exact: true,
    key: menuItemKeys.REVIEW_MANAGEMENT
  }
]

export const statsMenuItems = [
  {
    action: 'dashboard',
    title: 'message.dashboard',
    active: true,
    routeName: 'dashboard',
    exact: true,
    items: null,
    key: menuItemKeys.DASHBOARD
  },
  {
    action: 'insights',
    title: 'message.advancedInsights',
    active: true,
    routeName: 'advancedInsights',
    exact: true,
    items: null,
    key: menuItemKeys.ADVANCED_INSIGHTS
  },
  {
    action: 'analytics',
    title: 'message.productInsights.productInsights',
    active: true,
    routeName: 'productInsights',
    exact: true,
    items: null,
    key: menuItemKeys.PRODUCT_INSIGHTS
  },
  {
    action: 'trending_up',
    title: 'message.reports',
    active: true,
    items: null,
    routeName: 'publishedReports',
    exact: true,
    key: menuItemKeys.PUBLISHED_REPORTS
  },
  {
    action: 'sync_alt',
    title: 'message.postbackUrl.postbackUrls',
    active: true,
    items: null,
    routeName: 'postbackUrl',
    exact: true,
    key: menuItemKeys.POSTBACK_URLS
  },
  {
    action: 'summarize',
    title: 'message.mediaDataUpload.mediaDataUpload',
    active: true,
    items: null,
    routeName: 'mediaDataUpload',
    exact: true,
    key: menuItemKeys.MEDIA_DATA_UPLOAD
  },
  {
    action: 'link',
    title: 'message.trackingLink.trackingLink',
    active: true,
    items: null,
    routeName: 'trackingLink',
    exact: true,
    key: menuItemKeys.TRACKING_LINK
  },
  {
    action: 'insert_chart_outlined',
    title: 'message.adhocQueryReports.queryReports',
    active: true,
    items: null,
    routeName: 'queryReports',
    exact: true,
    key: menuItemKeys.ADHOC_QUERY_REPORTS
  }
]
export const walletMenuItem = {
  action: 'account_balance_wallet',
  title: 'message.wallet',
  active: true,
  items: null,
  routeName: 'wallet',
  exact: true,
  component: 'balance',
  key: menuItemKeys.WALLET
}

export const affiliateMenuItem = [
  {
    action: 'dashboard',
    title: 'message.dashboard',
    active: true,
    routeName: 'affiliate-dashboard',
    exact: true,
    items: null,
    key: menuItemKeys.DASHBOARD
  },
  {
    action: 'sync_alt',
    title: 'message.postbackUrl.postbackUrls',
    active: true,
    items: null,
    routeName: 'affiliate-postbackUrl',
    exact: true,
    key: menuItemKeys.POSTBACK_URLS
  },
  {
    action: 'summarize',
    title: 'message.mediaDataUpload.mediaDataUpload',
    active: true,
    items: null,
    routeName: 'affiliate-mediaDataUpload',
    exact: true,
    key: menuItemKeys.MEDIA_DATA_UPLOAD
  },
  {
    action: 'link',
    title: 'message.trackingLink.trackingLink',
    active: true,
    items: null,
    routeName: 'affiliate-trackingLink',
    exact: true,
    key: menuItemKeys.TRACKING_LINK
  },
  {
    action: 'insert_chart_outlined',
    title: 'message.adhocQueryReports.queryReports',
    active: true,
    items: null,
    routeName: 'affiliate-queryReports',
    exact: true,
    key: menuItemKeys.ADHOC_QUERY_REPORTS
  },
  {
    action: 'sell',
    title: 'message.deal.dealsDashboard',
    active: true,
    items: null,
    routeName: 'affiliate-deals',
    exact: true,
    key: menuItemKeys.DEALS_DASHBOARD
  }
]

