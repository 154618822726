
import { menuItemKeys } from 'Store/modules/sidebar/data'
const defaultCampaignLaunchSteps = {
  'budgetAndDates': {
    step: 1,
    title: 'message.budgetAndDates'
  },
  'creatives': {
    step: 2,
    title: 'message.creatives'
  },
  'audience': {
    step: 3,
    title: 'message.audience'
  },
  'paymentAndReview': {
    step: 4,
    title: 'message.paymentAndReview'
  }
};

const localBusinessCampaignLaunchSteps = {
  'creatives': {
    step: 1,
    title: 'message.creatives'
  },
  'audience': {
    step: 2,
    title: 'message.audience'
  },
  'budgetAndDates': {
    step: 3,
    title: 'message.budgetAndDates'
  },
  'paymentAndReview': {
    step: 4,
    title: 'message.paymentAndReview'
  }
};

const defaultCreativesOrder = {
  'social': 1,
  'search': 2,
  'linkedin': 3,
  'banner': 4
};

const localBusinessCreativesOrder = {
  'search': 1,
  'social': 2,
  'banner': 3
};

const defaultStoreProfileSettingsEcommerce = {
  logo: {
    enabled: true,
    editable: true
  },
  name: {
    enabled: true,
    editable: true
  },
  email: {
    enabled: true,
    editable: true
  },
  currency: {
    enabled: true,
    editable: true
  },
  timezone: {
    enabled: true,
    editable: true
  },
  location: {
    enabled: true,
    editable: true
  },
  address: {
    enabled: true,
    editable: true
  }
};
const defaultStoreProfileSettingsHq = {
  logo: {
    enabled: true,
    editable: false
  },
  name: {
    enabled: true,
    editable: true
  },
  email: {
    enabled: false,
    editable: false
  },
  currency: {
    enabled: true,
    editable: false
  },
  timezone: {
    enabled: true,
    editable: false
  },
  location: {
    enabled: true,
    editable: false
  },
  address: {
    enabled: true,
    editable: true
  }
};

export const categorySettingsData = {
  'e-commerce': {
    featureSettings: {
      newStoreLinking: true,
      assetsLinking: true,
      audienceAddition: true,
      creativeEdit: true
    },
    supportedMenus: [
      menuItemKeys.STORE_PROFILE,
      menuItemKeys.PRODUCT_FEED,
      menuItemKeys.DASHBOARD,
      menuItemKeys.PUBLISHED_REPORTS,
      menuItemKeys.LINK_ASSETS,
      menuItemKeys.ADD_AUDIENCE,
      menuItemKeys.WALLET,
      menuItemKeys.PRODUCT_INSIGHTS
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsEcommerce
  },
  'hq-franchise': {
    featureSettings: {
      newStoreLinking: false,
      assetsLinking: false,
      audienceAddition: false,
      creativeEdit: false,
      promos: true,
      dayPartingSchedules: true
    },
    supportedMenus: [
      menuItemKeys.DASHBOARD,
      menuItemKeys.PUBLISHED_REPORTS,
      menuItemKeys.WALLET,
      menuItemKeys.REVIEW_MANAGEMENT,
      menuItemKeys.ADVANCED_INSIGHTS
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsHq
  },
  'local-business': {
    featureSettings: {
      newStoreLinking: true,
      assetsLinking: true,
      audienceAddition: true,
      creativeEdit: true
    },
    supportedMenus: [
      menuItemKeys.STORE_PROFILE,
      menuItemKeys.DASHBOARD,
      menuItemKeys.PUBLISHED_REPORTS,
      menuItemKeys.LINK_ASSETS,
      menuItemKeys.ADD_AUDIENCE,
      menuItemKeys.WALLET
    ],
    campaignLaunchSteps: localBusinessCampaignLaunchSteps,
    creativesOrder: localBusinessCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsEcommerce
  },
  'creative-builder': {
    featureSettings: {
      newStoreLinking: false,
      assetsLinking: false,
      audienceAddition: false,
      creativeEdit: false,
      promos: false,
      dayPartingSchedules: false
    },
    supportedMenus: [
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsHq
  },
  'brand-portal': {
    featureSettings: {
      newStoreLinking: false,
      assetsLinking: false,
      audienceAddition: false,
      creativeEdit: false,
      promos: false,
      dayPartingSchedules: false
    },
    supportedMenus: [
      menuItemKeys.DASHBOARD,
      menuItemKeys.PUBLISHED_REPORTS
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsHq
  },
  'affiliate': {
    featureSettings: {
      newStoreLinking: false,
      assetsLinking: false,
      audienceAddition: false,
      creativeEdit: false,
      promos: false,
      dayPartingSchedules: false
    },
    supportedMenus: [
      menuItemKeys.DASHBOARD,
      menuItemKeys.POSTBACK_URLS,
      menuItemKeys.MEDIA_DATA_UPLOAD,
      menuItemKeys.TRACKING_LINK,
      menuItemKeys.ADHOC_QUERY_REPORTS,
      menuItemKeys.DEALS_DASHBOARD
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsHq
  },
  'rich-media-ad-builder': {
    featureSettings: {
      newStoreLinking: false,
      assetsLinking: false,
      audienceAddition: false,
      creativeEdit: false,
      promos: false,
      dayPartingSchedules: false
    },
    supportedMenus: [
    ],
    campaignLaunchSteps: defaultCampaignLaunchSteps,
    creativesOrder: defaultCreativesOrder,
    storeProfileSettings: defaultStoreProfileSettingsHq
  }
}

export const flavourSettingsData = {
  'Shopify': {
    category: 'e-commerce'
  },
  'magento': {
    category: 'e-commerce'
  },
  'Dominos': {
    category: 'hq-franchise'
  },
  'Toyota': {
    category: 'hq-franchise'
  },
  'local-business': {
    category: 'local-business'
  },
  'creative-builder': {
    category: 'creative-builder'
  },
  'brand-portal': {
    category: 'brand-portal'
  },
  'rich-media-ad-builder': {
    category: 'rich-media-ad-builder'
  }
}