export const mediaElementReadyState = Object.freeze({
  HAVE_NOTHING: 0,
  HAVE_METADATA: 1,
  HAVE_CURRENT_DATA: 2,
  HAVE_FUTURE_DATA: 3,
  HAVE_ENOUGH_DATA: 4
});

export const defaultTtsConfiguration = Object.freeze({
  pitch: 0,
  speakingRate: 1,
  text: '',
  voice: {
    languageCode: 'en-US',
    name: 'en-US-Wavenet-D'
  },
  data: '',
  isApiCallRequired: false
});

export const defaultVoiceTypes = ['Wavenet', 'Standard', 'Neural2', 'Polyglot', 'Studio', 'News', 'Casual', 'Journey'];
