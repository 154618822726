export const errorCodes = {
  InvalidHashkeyRequested: 60,
  EntityAlreadyExistsError: 101,
  EntityDoesNotExistException: 104,
  MediaPlanStatusUpdateFailedException: 110,
  NoCampaignsLinkedException: 111,
  StripeException: 201,
  EntityNotFoundInCacheException: 308,
  InvalidLoginCredentials: 51,
  ForbiddenPortalRequested: 56,
  ForbiddenBusinessProfileRequested: 113,
  InvalidPortalRequestedException: 55,
  InvalidBusinessProfileRequested: 112,
  Unauthorized: 401,
  TokenMalformedException: 61,
  TokenExpiredException: 62,
  TokenInvalidException: 63,
  InvalidRequestDataException: 102,
  UserAccountExpiredException: 52,
  EmailAlreadyVerifiedError: 65,
  ReviewManagementConfigurationError: 1204,
  CampaignAlreadyExistsException: 1501,
  ShortCodeAlreadyExistsException: 1502,
  AuthorizationCodeInvalidException: 80,
  AuthorizationCodeExpiredException: 81,
  PasswordExpiredException: 74,
  OldPasswordException: 75,
  RefreshTokenExpiredException: 76
};
