import EventEmitter from 'eventemitter3'
import api from 'Api';
import { LoginProviderTypes } from '../constants/loginProviderTypes';
import { apiMethods } from 'Api'
import { UserRoles } from '../constants/userRoles';

export class AuthService {
  constructor() {
    this.login = this.login.bind(this)
    this.setSession = this.setSession.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signUpUser = this.signUpUser.bind(this);
    this.getPortalData = this.getPortalData.bind(this);
    this.authenticated = this.isAuthenticated()
    this.authNotifier = new EventEmitter();
  }

  async signUpUser(user) {
    var userData = {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      loginPassword: user.password,
      isTosAccepted: true,
      loginProviderType: user.loginProviderType,
      portalDomain: user.portalDomain,
      loginName: user.loginName,
      isEmailVerified: !!user.isEmailVerified,
      roleId: UserRoles.RoimaticClientAccess
    };
    var response = await api.post('/roimatic/users', userData);
    return response.data.id;
  }

  async login(loginDetails) {
    return (await apiMethods.loginWithEmailAndPassword(loginDetails.emailAddress, loginDetails.password));
  }

  async loginWithEmbed(embedToken) {
    let response = await api.post('/authentication/loginWithEmbedToken', {
      embedToken,
      portal: window.location.host
    });
    return response.data;
  }

  async getPortalData(portalDomain) {
    let response = await api.get('/portals', {
      headers: { 'X-Bypass-Login': true },
      params: {
        domain: portalDomain
      }
    });
    let responseData = response.data.data;
    return responseData && Array.isArray(responseData) && responseData.length > 0 ? responseData[0] : null;
  }

  async loginWithProvider(loginWithProviderDetails = {}) {
    if (!loginWithProviderDetails.hasOwnProperty('loginProviderType')) {
      loginWithProviderDetails.loginProviderType = LoginProviderTypes.Google;
    }

    return (await apiMethods.loginWithProvider(loginWithProviderDetails?.loginProviderType, loginWithProviderDetails?.authorizationCode));
  }

  handleAuthentication() {
  }

  setSession() {
  }

  async logout() {
    return (await apiMethods.logout());
  }

  isAuthenticated() {
  }
}
