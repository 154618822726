const AdvancedInsights = () => import(/* webpackChunkName: "AdvancedInsights" */ 'Features/advancedInsights/Main');

const AdvancedInsightsRoute =  {
  name: 'advancedInsights',
  path: 'advancedInsights',
  component: AdvancedInsights,
  meta: {
    requiresAuth: true,
    title: 'message.advancedInsights',
    breadcrumb: 'message.advancedInsights'
  }
}

export default AdvancedInsightsRoute;
